import { AxiosError } from 'axios';
import concat from 'lodash/concat';
import isEmpty from 'lodash/isEmpty';
import { Action } from 'redux';
import {
  actionTypes,
  FormAction,
  getFormValues,
  initialize,
  startSubmit,
  stopSubmit,
} from 'redux-form';
import { combineEpics, Epic } from 'redux-observable';
import { EMPTY, from, of } from 'rxjs';
import {
  catchError,
  debounceTime,
  filter,
  map,
  mergeMap,
  startWith,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';
import { getType, isActionOf } from 'typesafe-actions';
import { LAYOUT_INFO_MODAL_ID } from '../../constants';
import { MobileApiMessageTemplate, MobileApiValidationErrorResponse } from '../../mobile-api-types';
import { endCloning, startCloning } from '../clone/CloneActions';
import { core_t } from '../CoreLocale';
import { growl } from '../layout/LayoutActions';
import { hideModal, showModal } from '../modal/ModalActions';
import { showConfirmationModal } from '../modal/ModalEpics';
import { navigateTo } from '../navigation/NavigationActions';
import { RootState } from '../RootReducer';
import { searchPagerRefresh } from '../search-pager/SearchPagerActions';
import store, { queryClient } from '../store';
import { BackgroundRef, CancellableRef, ConfirmRef, req } from '../utils/api';
import {
  commonClone,
  commonDelete,
  commonSave,
  confirmBeforeExecuting,
  pristinify,
  silentDelete,
} from './FormActions';
import { getFirstOnScreenErrorContainer, processError } from './FormUtils';

// Show a confirmation modal and on confirm, send a network request to delete a thing.
export const commonDeleteEpic: Epic<Action, Action, RootState, any> = action$ =>
  action$.pipe(
    filter(isActionOf([commonDelete, confirmBeforeExecuting])),
    switchMap(action => {
      const options = action.payload.options;
      return showConfirmationModal(
        action$,
        {
          title: options.title,
          message: options.message,
          textConfirm: options.textConfirm,
          textConfirmLabel: options.textConfirmLabel,
          isDestructive: options.isDestructive || action.type === getType(commonDelete),
          confirmTitle: options.confirmTitle,
        },
        () =>
          req(action.payload.request, store, ConfirmRef).pipe(
            switchMap(response => {
              if (options.pagerId) {
                queryClient.invalidateQueries({ queryKey: [options.pagerId] });
              }

              return [
                ...(options.successMessage ? [growl(options.successMessage)] : []),
                hideModal(),
                ...(options.pagerId ? [searchPagerRefresh(options.pagerId, -1)] : []),
                ...(options.successActions ? options.successActions(response) : []),
              ];
            }),
            catchError(error =>
              from([
                hideModal(),
                showModal({
                  id: LAYOUT_INFO_MODAL_ID,
                  response: error,
                  failureMessage: options.failureMessage,
                  errorOverride: options.errorOverride,
                }),
                ...(options.failureActions ? options.failureActions(error) : []),
              ])
            )
          )
      );
    })
  );

// Without showing a confirmation modal, send a network request to delete a thing.
export const silentDeleteEpic: Epic<Action, Action, RootState, any> = action$ =>
  action$.pipe(
    filter(isActionOf(silentDelete)),
    switchMap(action => {
      const options = action.payload.options;
      return req<any>(action.payload.request, store, CancellableRef).pipe(
        switchMap(response => {
          if (options.pagerId) {
            queryClient.invalidateQueries({ queryKey: [options.pagerId] });
          }

          return [
            ...(options.successMessage ? [growl(options.successMessage)] : []),
            hideModal(),
            ...(options.pagerId ? [searchPagerRefresh(options.pagerId, -1)] : []),
            ...(options.successActions ? options.successActions(response) : []),
          ];
        }),
        catchError(error =>
          from([
            hideModal(),
            ...(options.failureMessage ? [growl(options.failureMessage)] : []),
            ...(options.failureActions ? options.failureActions(error) : []),
          ])
        )
      );
    })
  );

// Send a network request to create or edit a thing.
export const commonSaveEpic: Epic<Action, Action, RootState, any> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(commonSave)),
    withLatestFrom(state$),
    mergeMap(([action, state]) => {
      const { formId, formData, request, isUpdate, options: opts } = action.payload;
      return req<any>(request, store, ConfirmRef).pipe(
        map(response => {
          const actions = opts.successMessage
            ? [growl(opts.successMessage, { type: 'success' })]
            : opts.resourceName
              ? [
                  growl(
                    core_t(isUpdate ? ['message', 'updateSuccess'] : ['message', 'createSuccess'], {
                      resourceName: opts.resourceName,
                      data: response.data[opts.nameProp || 'name'],
                    }),
                    {
                      type: 'success',
                    }
                  ),
                ]
              : [];
          return { response, error: null, actions };
        }),
        catchError((error: AxiosError | any) => of({ response: null, error, actions: [] })),
        switchMap(({ response, error, actions }) => {
          const formActions =
            response && !!formId && !opts.stopReinitialize
              ? [
                  initialize(formId, formData, {
                    keepDirty: false,
                    updateUnregisteredFields: true,
                    keepSubmitSucceeded: true,
                  }),
                ]
              : [];

          const { errors, actions: errorActions } = error
            ? processError(
                formId,
                formData,
                opts.errorOverride?.(error) ?? error,
                core_t(['message', 'failedToSave'], {
                  resourceName: opts.resourceName || core_t(['label', 'information']),
                })
              )
            : { errors: undefined, actions: [] };

          const successActions =
            opts.successActions && response
              ? concat([hideModal()], opts.successActions(response))
              : [];

          const failureActions = opts.failureActions && error ? opts.failureActions(error) : [];

          return [
            ...actions,
            ...formActions,
            ...errorActions,
            stopSubmit(formId, errors),
            ...successActions,
            ...failureActions,
          ];
        }),
        startWith(startSubmit(formId))
      );
    })
  );

// After a redux form receives SET_SUBMIT_FAILED or STOP_SUBMIT with errors, find the first error container
// after a short delay on the dom and scroll to it
export const scrollToErrorAfterTimeoutEpic: Epic<FormAction, Action, RootState, any> = action$ =>
  action$.pipe(
    filter(
      action =>
        action.type === actionTypes.SET_SUBMIT_FAILED ||
        (action.type === actionTypes.STOP_SUBMIT && !isEmpty(action.payload))
    ),
    debounceTime(100),
    switchMap((action: FormAction) => {
      const formId = action.meta.form;
      const firstOnScreenErrorContainer = getFirstOnScreenErrorContainer(formId) as HTMLElement;
      if (firstOnScreenErrorContainer) {
        // If the form is in a container with its own scrollbar separate from the window, scroll that container instead
        const scrollablePane = document.getElementById('scrollable-pane-form');
        if (scrollablePane) {
          scrollablePane.scrollTop = firstOnScreenErrorContainer.offsetTop - 150;
        } else {
          const top =
            firstOnScreenErrorContainer.getBoundingClientRect().top + window.scrollY - 150;
          window.scroll({ top, behavior: 'smooth' });
        }
      }
      return EMPTY;
    })
  );

// Marks a given form as pristine
export const pristinifyEpic: Epic<FormAction, Action, RootState, any> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(pristinify)),
    withLatestFrom(state$),
    map(([action, state]) =>
      initialize(action.payload.formId, getFormValues(action.payload.formId)(state), {
        keepDirty: false,
        updateUnregisteredFields: true,
        keepSubmitSucceeded: true,
        ...action.payload.options,
      })
    )
  );

export const commonCloneEpic: Epic<Action, Action, RootState, any> = action$ =>
  action$.pipe(
    filter(isActionOf(commonClone)),
    switchMap(action => {
      const { successMessage, failureMessage, editRouteFn, successActions, failureActions } =
        action.payload.options;

      return req<MobileApiMessageTemplate>(action.payload.request, store, BackgroundRef).pipe(
        switchMap(response => {
          const cloneId = response.data.id;
          return [
            growl(successMessage),
            ...(editRouteFn ? [navigateTo(`${editRouteFn(cloneId)}?clone=true`)] : []),
            ...(successActions ? successActions(response) : []),
            endCloning(),
          ];
        }),
        catchError((error: AxiosError<MobileApiValidationErrorResponse>) => {
          const errorMessage = error.response?.data?.reason?.[0]?.message || failureMessage;
          return [
            growl(errorMessage, { type: 'danger' }),
            ...(failureActions ? failureActions(error) : []),
            endCloning(),
          ];
        }),
        startWith(startCloning())
      );
    })
  );

export default combineEpics(
  commonDeleteEpic,
  silentDeleteEpic,
  commonSaveEpic,
  scrollToErrorAfterTimeoutEpic,
  pristinifyEpic,
  commonCloneEpic
);
